@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9dkfxk');
  src: local('icomoon'),  url('../fonts/icomoon.eot?9dkfxk#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9dkfxk') format('truetype'),
    url('../fonts/icomoon.woff?9dkfxk') format('woff'),
    url('../fonts/icomoon.svg?9dkfxk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"], .iconify {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-goal_17:before {
  content: "\e900";
  color: #fff;
}
.icon-goal_16:before {
  content: "\e901";
  color: #fff;
}
.icon-goal_15:before {
  content: "\e902";
  color: #fff;
}
.icon-goal_14:before {
  content: "\e903";
  color: #fff;
}
.icon-goal_13:before {
  content: "\e904";
  color: #fff;
}
.icon-goal_12:before {
  content: "\e905";
  color: #fff;
}
.icon-goal_11:before {
  content: "\e906";
  color: #fff;
}
.icon-goal_10:before {
  content: "\e907";
  color: #fff;
}
.icon-goal_9:before {
  content: "\e908";
  color: #fff;
}
.icon-goal_8:before {
  content: "\e909";
  color: #fff;
}
.icon-goal_7:before {
  content: "\e90a";
  color: #fff;
}
.icon-goal_6:before {
  content: "\e90b";
  color: #fff;
}
.icon-goal_5:before {
  content: "\e90c";
  color: #fff;
}
.icon-goal_4:before {
  content: "\e90d";
  color: #fff;
}
.icon-goal_3:before {
  content: "\e90e";
  color: #fff;
}
.icon-goal_2:before {
  content: "\e90f";
  color: #fff;
}
.icon-goal_1:before {
  content: "\e910";
  color: #fff;
}
.icon-fullscreen-exit:before {
  content: "\e915";
}
.icon-fullscreen:before {
  content: "\e911";
}
.icon-road:before {
  content: "\e912";
}
.icon-stats-dots:before {
  content: "\e913";
}
.icon-users:before {
  content: "\e914";
}
.icon-group:before {
  content: "\e914";
}
.icon-team:before {
  content: "\e914";
}
.icon-members:before {
  content: "\e914";
}
.icon-community:before {
  content: "\e914";
}
.icon-collaborate:before {
  content: "\e914";
}
