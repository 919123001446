// allow users to import font at their discretion
// @import url('//fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700&display=swap');
@import 'reset';
@import 'variables';
@import 'animations';
@import 'functions';
@import 'fonts';
@import 'mixins';
@import 'mado-theme';
@import 'sdg-theme';

@mixin horizontally-padded {
  margin: 10px;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.kt-dashboard-app {
  .mado-dashboard {
    font-family: 'PT Sans Narrow';
    .strokable {
      fill: $white;
    }
    h2 {
      font-size: 1.7em;
      font-weight: bold;
      font-family: 'PT Sans Narrow';
    }
    h3 {
      font-size: 1.5em;
      text-align: center;
      color: $white;
      font-family: 'PT Sans Narrow';
    }
    &__header {
      @media screen and (min-width: $desktop-width) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 5px;
      }
      &__select-region {
        min-width: 240px;
      }
    }
    &__metric-groups {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &__metric-group {
      padding: 10px;
      margin-top: 10px;
      margin-right: 5px;
      margin-left: 5px;
      color: $white;
      flex-grow: 2;

      // background: linear-gradient(45deg, #fafafa, #cecece);
      background: linear-gradient($light-blue 0%, $light-green 100%);
      .mado-metrics {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        .mado-metric-placeholder {
          width: 250px;
          height: 370px;
          margin-top: 10px;
          margin-right: 5px;
          margin-left: 5px;
        }
        .mado-metric {
          padding: 10px 5px;
          width: 240px;
          height: 350px;
          border-radius: $border-radius;
          // background: linear-gradient($light-blue 0%, $light-green 100%);
          background: rgba(255, 255, 255, 0.25);
          opacity: 0.93;
          box-shadow: 0px 3px 6px rgba(0, 113, 82, 0.18);
          position:relative;
          transition: background 1s, border-radius 1s, box-shadow 1s, width 0.5s, height 0.5s, position 0.5s;
          transition: width 1s delay 1s;
          animation: fadein 1s;
          &:hover {
            opacity: 1;
            box-shadow: 0px 3px 6px rgba(0, 113, 82, 0.5);
            background: rgba(255, 255, 255, 0.33);
            border-radius: 2px;
          }
          &-expanded {
            position: absolute;
            z-index: 9;
            width: 80%;
            height: auto;
            // top: 50%;
            left: 50%;
            transform: translate(-50%);
            opacity: 1;
            background-color: $white;
            &:hover {
              opacity: 1;
              background-color: $white;
            }
          }
          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__heading {
              font-weight: normal;
              font-size: 18px;
              text-align: left;
              // color: #fff;
              height: 2.4em;
              display:table-cell;
              vertical-align: middle;
              position: relative;
            }
            &__expand {
              display: table-cell;
              vertical-align: middle;
              position: relative;
              a {
                // @include expand-icon($white);
                // @include header-icon;
                height: 20px;
                width: 20px;
                float: right;
              }
            }
            @include horizontally-padded;
            margin-top: 10px;
          }
          &__primary {
            @include horizontally-padded;
            border-radius: $border-radius;
            background: rgba(255, 255, 255, 0.85);
            text-align: center;
            padding:24px 10px;
            color: #333;
            &__value {
              font-size:48px;
              font-weight:bold;
              color: $green;
            }
          }
          &__goal {
            @include horizontally-padded;
            text-align:center;
            // color: $white;
            font-weight: light;
          }
          &__trend {
            display: flex;
            align-items: center;
            p {
              color: #333;
              font-size: 0.8em;
              width: auto;
              margin: 0 auto;
              padding: 3px 6px;
              font-style: italic;
              background-color: $highlight;
            }
          }
          &__footer {
            @include horizontally-padded;
            margin-bottom: 10px;
            text-align:center;
            @include clearfix;
          }
          &__button {
            box-sizing: border-box;
            padding: 0.5em 1em;
            width: $button-width;
            border-radius: 6px;
            background: linear-gradient(#fff 0%, #d5edff 100%);
            box-shadow: 0px 1px 6px rgba(76, 82, 93, 0.44);
            text-decoration: none;
            font-weight:bold;
            color:$blue;
            margin-top: 2em;
            display:inline-block;
            bottom: 10px;
            position: absolute;
            left: calc(50% - #{$button-width/2});
          }
          &__chart {
            padding: 1em;
          }
        }
      }
    }
    .select-location {
      &__control {
        padding-left: 6px;
        padding-right: 6px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
        &--is-focused {
          border: none;
          border-color: transparent;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
        }
      }
      &__option {
        padding: 2px 6px;
        background-color: $white;
        color: $black;
      }
      &__indicator-separator {
        display: none;
      }
    }
  }
}
