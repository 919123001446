.kt-dashboard-app.mado {
  .mado-dashboard {

    &__metric-group {

      .mado-metric__header {
        &__expand {
          display: none;
        }
      }

      // icons
      .mado-metric__header__icon {
        @include header-icon;
        @include chart-icon;
      }
      &.human-capital {
        .mado-metric__header__icon {
          @include person-icon;
        }
      }
    }
  }
}