$dark-gray: #666;

$goal-colors: (
  "1": #E3243B,
  "2": #DDA83A,
  "3": #4C9E37,
  "4": #C9293C,
  "5": #FF3A21,
  "6": #26BDE1,
  "7": #FCC20D,
  "8": #A01941,
  "9": #FD6925,
  "10": #DE1367,
  "11": #FC9C24,
  "12": #BF8B2E,
  "13": #3F7E44,
  "14": #0A97D9,
  "15": #56BF2B,
  "16": #00689D,
  "17": #19486A
);  

.kt-dashboard-app.sdg {

  .mado-dashboard {

    &__metric-group {
      //background
      background: none;
      h3.group-title {
        display: none;
      }
      .mado-metrics {

        position: relative;

        .mado-metric-placeholder {
          width: 280px;
          height: 330px;
        }

        // metric
        .mado-metric {
          padding: 0;
          box-shadow: none;
          width: 280px;
          height: 330px;
          &__chart {
            background-color: #fbfbe5;
          }
          &__header {
            margin: 0;
            padding: 0.25rem 0.5em;
            background-color: $dark-gray;
            text-shadow: 1px 1px 1px rgba(0,0,0,.2);
            &__heading {
              font-size: 1.5em;
              font-weight: bold;
            }
            &__expand {
              a {
                padding: 6px;
                box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
                cursor: pointer;
              }
            }
          }
          &-expanded {
            width: 80%;
            height: auto;
          }
          @each $index, $color in $goal-colors {
            &.metric-code-#{$index} {
              border: 1px solid lighten($color, 20%);
              .mado-metric {
                &__header {
                  background-color: $color;
                }
                &__primary {
                  background-color: transparent;
                  padding: 10px;
                  &__value {
                    color: $color;
                  }
                }
              }
            }
          }
          &__goal {
            color: $black;
          }
        }
  
        // icons
        .mado-metric__header__icon {
          @include header-icon;
          &:before {
            font-size: 2.4rem;
          }
        }
        &.human-capital {
          .mado-metric__header__icon {
          }
        }

      }

    }

    .select-location {
      &__control {
        box-shadow: none;
        border: 1px solid #c9c9c9;
        &--is-focused {
          box-shadow: none;
        }
      }
      &__option {
        padding: 2px 6px;
        background-color: $white;
        color: $black;
      }
      &__indicator-separator {
        display: none;
      }
    }

  }

}