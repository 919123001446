// adds gradient background and down arrow
@mixin chart-icon {
  &:before {
    content: "\e913";
  }
}

@mixin person-icon {
  &:before {
    content: "\e914";
  }
}

// image backgrounds
@mixin header-icon {
  background-repeat: no-repeat;
  // background-size: cover;
  background-position: middle left;
  display: table-cell;
  vertical-align: middle;
  padding-right: 0.25em;
  font-size: 32px;
  @extend .iconify;
}